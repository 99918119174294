import React, { useEffect } from "react"
import SEO from "../../components/Seo/Seo"

import { navigate } from "gatsby"

const introductionMetadata = {
  Title: "Flipbase - Geïntegreerde asynchrone videotechnologie",
  Keywords: ["Flipbase", "asynchrone videotechnologie"],
  Description:
    "Flipbase is een videotechnologiebedrijf, met geïntegreerde asynchrone videocommunicatie voor recruitment professionals. Bekijk onze nieuwe website voor meer informatie.",
  Image: "/seo-image-general.png",
}

export default () => {
  useEffect(() => {
    navigate("/introduction")
  }, [])

  return (
    <SEO
      title={introductionMetadata.Title}
      description={introductionMetadata.Description}
      image={introductionMetadata.Image}
      keywords={introductionMetadata.Keywords}
    />
  )
}
